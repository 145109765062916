<template>
    <div class="place_order_main" v-loading="isSubmitOrder">
        <div class="main_item main_line">
            <div class="car_nav flex_start">
                <div :class="['car_nav_item',carList_index == index?'car_nav_active':'']" v-for="(item,index) in carList" :key="item.cart_type_name" @click="changeNav(index)">{{item.cart_type_name}}</div>
            </div>
            <el-carousel ref="car" indicator-position="none" :autoplay="false" :initial-index="carList_index" height="240px" @change="carouselChange">
                <el-carousel-item v-for="(item,index) in carList" :key="item.cart_type_name">
                    <div class="first_carousel column_center" v-if="index == 0">
                        <img class="carousel_icon" src="@/assets/imgs/u63.png" alt="">
                        <div class="column_center carousel_text">
                            <span>输入您要货运的体积/重量/件数</span>
                            <span>即可获得小助手的推荐车型</span>
                        </div>
                        <div class="carousel_btn flex_center" @click.stop="choice_car_show = true">输入参数</div>
                    </div>
                    <div class="onther_carousel column_stretch_center" v-else>
                        <div class="car_info_item2 flex_center">
                            <img class="car_img" :src="item.image_url"></img>
                            <div class="car_info_txt_view2">
                                <span>载重 :{{item.max_load}}吨</span>
                                <span>长宽高 :{{item.lenght}}米*{{item.width}}米*{{item.height}}米</span>
                                <span>载货体积 :{{item.max_bulk}}立方</span>
                            </div>
                        </div>
                        <div class="pendant_list_view">
                            <div :class="['pendant_item',pendant_id.length==0?'select_pandent':'']" @click.stop="selectPandent">
                                <span>不限车型</span>
                            </div>
                            <div :class="['pendant_item',hasString(pendant_id,pitem.id)?'select_pandent':'']" v-for="(pitem,pidx) in item.pendant_list" :key="pidx" @click.stop="selectPandent(pitem)">
                                <span>{{pitem.pendant_name}}</span>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="place_order">
                <div class="place_header flex_between_center">
                    <div class="flex_start_center">
                        <span class="place_txt1">输入起点/目的地</span>
                        <el-switch class="switchStyle" active-value="0" inactive-value="1" v-model="useType" inactive-text="实时下单" active-text="预约下单" inactive-color="#dddddd"></el-switch>
                    </div>
                    <span class="import_text" @click.stop="onShowRoute">导入常用路线</span>
                </div>
                <div class="distance_wrap">
                    <div class="form_booking form_item flex_center" v-if="useType == '0'">
                        <div class="form_label require_label">预约时间：</div>
                        <div class="flex_start" style="flex:1 1;">
                            <el-select style="margin-right:10px;" v-model="booking_date" placeholder="请设置预约日期">
                                <el-option
                                    v-for="item in newDateArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-select v-model="booking_time" placeholder="请设置预约时间">
                                <el-option
                                    v-for="item in newTimeArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <el-steps direction="vertical" :active="1">
                        <!-- 装货 -->
                        <el-step class="step_item start_step_item">
                            <template #icon>
                                <div class="step_icon">装</div>
                            </template>
                            <template #description>
                                <div class="step_description">
                                    <div class="form_item flex_center">
                                        <div class="form_label require_label">发货地址：</div>
                                        <el-input v-model="startLocation.address" readonly @click.native="choiceStartLocation" placeholder="请输入地址信息，自动匹配相关地址"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">楼层门牌号：</div>
                                        <el-input v-model="startLocation.floor" placeholder="请输入楼层门牌号（选填）"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">联系人方式：</div>
                                        <div class="flex_between_center" style="width: 100%;">
                                            <el-input v-model="startLocation.linkman_name" class="input_username" placeholder="请输入联系人姓名（选填）"></el-input>
                                            <el-input v-model="startLocation.linkman_phone" placeholder="请输入联系人手机号码（选填）" type="number"></el-input>
                                        </div>
                                    </div>
                                </div>
                                <img class="add_icon" src="@assets/imgs/add.png" alt="" @click.stop="onAddStartWayPoint">
                            </template>
                        </el-step>
                        <el-step class="step_item start_step_item"  v-for="(item,adx) in addWayPointList" :key="'add' + adx">
                            <template #icon>
                                <div class="step_icon">装</div>
                            </template>
                            <template #description>
                                <div class="step_description">
                                    <div class="form_item flex_center">
                                        <div class="form_label require_label">发货地址：</div>
                                        <el-input v-model="item.address" readonly @click.native="choiceStartLocationPoint(adx)" placeholder="请输入地址信息，自动匹配相关地址"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">楼层门牌号：</div>
                                        <el-input v-model="item.floor" placeholder="请输入楼层门牌号（选填）"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">联系人方式：</div>
                                        <div class="flex_between_center" style="width: 100%;">
                                            <el-input v-model="item.linkman_name" class="input_username" placeholder="请输入联系人姓名（选填）"></el-input>
                                            <el-input v-model="item.linkman_phone" placeholder="请输入联系人手机号码（选填）" type="number"></el-input>
                                        </div>
                                    </div>
                                </div>
                                <img class="add_icon" src="@assets/imgs/del.png" alt="" @click.stop="onDelStartWayPoint(adx)">
                            </template>
                        </el-step>
                        <!-- 卸货 -->
                        <el-step class="step_item end_step_item"  v-for="(item,dex) in delWayPointList" :key="'del' + dex">
                            <template #icon>
                                <div class="step_icon" style="background: #ff6543;">卸</div>
                            </template>
                            <template #description>
                                <div class="step_description">
                                    <div class="form_item flex_center">
                                        <div class="form_label require_label">发货地址：</div>
                                        <el-input v-model="item.address" readonly @click.native="choiceEndLocationPoint(dex)" placeholder="请输入地址信息，自动匹配相关地址"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">楼层门牌号：</div>
                                        <el-input v-model="item.floor" placeholder="请输入楼层门牌号（选填）"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">联系人方式：</div>
                                        <div class="flex_between_center" style="width: 100%;">
                                            <el-input v-model="item.linkman_name" class="input_username" placeholder="请输入联系人姓名（选填）"></el-input>
                                            <el-input v-model="item.linkman_phone" placeholder="请输入联系人手机号码（选填）" type="number"></el-input>
                                        </div>
                                    </div>
                                </div>
                                <img class="add_icon" src="@assets/imgs/del.png" alt="" @click.stop="onDelEndWayPoint(dex)">
                            </template>
                        </el-step>
                        <el-step class="step_item end_step_item">
                            <template #icon>
                                <div class="step_icon" style="background: #ff6543;">卸</div>
                            </template>
                            <template #description>
                                <div class="step_description">
                                    <div class="form_item flex_center">
                                        <div class="form_label require_label">发货地址：</div>
                                        <el-input v-model="endLocation.address" readonly @click.native="choiceEndLocation" placeholder="请输入地址信息，自动匹配相关地址"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">楼层门牌号：</div>
                                        <el-input v-model="endLocation.floor" placeholder="请输入楼层门牌号（选填）"></el-input>
                                    </div>
                                    <div class="form_item flex_center">
                                        <div class="form_label">联系人方式：</div>
                                        <div class="flex_between_center" style="width: 100%;">
                                            <el-input v-model="endLocation.linkman_name" class="input_username" placeholder="请输入联系人姓名（选填）"></el-input>
                                            <el-input v-model="endLocation.linkman_phone" placeholder="请输入联系人手机号码（选填）" type="number"></el-input>
                                        </div>
                                    </div>
                                </div>
                                <img class="add_icon" src="@assets/imgs/add.png" alt="" @click.stop="onAddEndWayPoint">
                            </template>
                        </el-step>
                    </el-steps>
                </div>
            </div>
            <div class="price_detail">
                <div class="place_txt1">价格明细</div>
                <div class="price_wrap flex_start">
                    <div :class="['price_item','column_between_center',carType == 0?'price_active_bg':'']" @click.stop="selectCarType(0)">
                        <img v-if="carType == 0" src="@assets/imgs/u94.png" class="price_active_icon" alt="">
                        <div class="price_label"><span :class="['price_label_size',carType == 0?'price_active_color':'']">专车</span>外运全流程 一键省事</div>
                        <div class="flex_between_center" style="width: 100%;">
                            <span v-if="costData != null" :class="['price_num',carType == 0?'price_active_color':'']">￥{{costData && costData.zhuanche_money}}</span>
                            <div :class="['detail_btn',carType == 0?'price_active_color':'']" @click="checkPirce(0)">查看价格明细</div>
                        </div>
                    </div>
                    <div :class="['price_item','column_between_center',carType == 1?'price_active_bg':'']" @click.stop="selectCarType(1)">
                        <img v-if="carType == 1" src="@assets/imgs/u94.png" class="price_active_icon" alt="">
                        <div class="price_label"><span :class="['price_label_size',carType == 1?'price_active_color':'']">回程车</span>回程顺风 ,价格优惠</div>
                        <div class="flex_between_center" style="width: 100%;">
                            <span v-if="costData != null" :class="['price_num',carType == 1?'price_active_color':'']">￥{{costData && costData.huichengche_money}}</span>
                            <div :class="['detail_btn',carType == 1?'price_active_color':'']" @click="checkPirce(1)">查看价格明细</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_item main_rg_item">
            <div class="column_start">
                <div class="rg_form_title">输入货物参数</div>
                <div class="rg_input_area flex_start" v-if="isConfirmGoods">
                    <span class="rg_input_text">货物名称：</span>
                    <span class="rg_input_value">{{cargoDetails.cargo_name}}（{{cargoDetails.cargo_volume}}吨，{{cargoDetails.cargo_all_weight}}方，{{cargoDetails.cargo_num}}件）</span>
                    <span class="rg_input_edit" @click.stop="goods_parameter_show = true">修改</span>
                </div>
                <div class="rg_input_area flex_start" v-else>
                    <span class="rg_input_text">货物名称</span>
                    <span class="rg_input_value" style="color:#999;">请设置货物参数</span>
                    <span class="rg_input_edit" @click.stop="goods_parameter_show = true">修改</span>
                </div>
            </div>
            <div class="column_start">
                <div class="rg_form_title">输入入仓信息</div>
                <div class="rg_input_area column_start">
                    <div class="rg_form_item flex_center">
                        <div class="rg_input_label">入仓PO号：</div>
                        <el-input v-model="warehouse.po_number" placeholder="请输入日常PO号信息"></el-input>
                    </div>
                    <div class="rg_form_item flex_center">
                        <div class="rg_input_label">唛头：</div>
                        <el-input v-model="warehouse.marks" placeholder="请输入唛头"></el-input>
                    </div>
                    <div class="rg_form_item flex_top_start form_no_margin">
                        <div class="rg_input_label">入仓文件：</div>
                        <el-upload 
                            class="form_upload" 
                            ref="upload" 
                            :file-list="order_file_list" 
                            action="upload_action" 
                            list-type="picture-card" 
                            multiple 
                            :limit="3" 
                            :http-request="onUploadFile" 
                        >
                            <i class="el-icon-plus"></i>
                            <div class="upload_cover_box" slot="file" slot-scope="{file}">
                                <el-progress
                                    v-if="file.status === 'uploading'"
                                    type="circle"
                                    :stroke-width="4"
                                    :percentage="parsePercentage(file)">
                                </el-progress>
                                <div style="height:100%;" v-if="file.status !== 'uploading' && file.url">
                                    <img
                                        v-if="onIndexOf(file.type) > -1"
                                        class="el-upload-list__item-thumbnail"
                                        :src="file.url" alt="">
                                    <img
                                        v-else 
                                        class="el-upload-list__item-thumbnail"
                                        src="@/assets/imgs/file_img2.png" alt="">
                                </div>
                                
                                <span class="el-upload-list__item-actions">
                                    <span
                                        class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreview(file)">
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleRemove(file)">
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </div>
                    <span class="rg_form_tips1">支持上传多个文件,最多三个</span>
                </div>
            </div>
            <div class="column_start">
                <div class="rg_form_title">输入订单信息</div>
                <div class="rg_input_area column_start">
                    <div class="rg_form_item flex_center">
                        <div class="rg_input_label">联系方式：</div>
                        <el-input v-model="order_phone" placeholder="请输入联系方式"></el-input>
                    </div>
                    <div class="rg_form_select flex_center">
                        <div class="rg_input_label">到达时间：</div>
                        <div class="flex_start" style="flex:1 1;">
                            <el-select class="form_date_select" v-model="order_date" placeholder="请选择日期">
                                <el-option
                                    v-for="item in newDateArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-select class="form_time_select" v-model="order_time" placeholder="请选择时间">
                                <el-option
                                    v-for="item in newTimeArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="rg_form_select flex_center form_no_margin">
                        <div class="rg_input_label">订单备注：</div>
                        <div class="flex_start" style="flex:1 1;">
                            <el-input class="remark_input" v-model="order_remarks" placeholder="请输入订单备注"></el-input>
                            <el-select v-if="remarksList.length > 0" v-model="remarksVal" placeholder="请选择订单备注">
                                <el-option-group
                                    v-for="group in remarksList"
                                    :key="group.label"
                                    :label="group.label">
                                    <el-option
                                        v-for="item in group.remarks"
                                        :key="item.id"
                                        :label="item.msg"
                                        @click.native="getRenark(item)"
                                        :value="item.id">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order_info flex_between_center">
                <span class="order_info_label">运费总价：</span>
                <span class="order_info_val">￥{{carType == 0 ? costData && costData.zhuanche_money : costData && costData.huichengche_money}}</span>
            </div>
            <div class="order_info flex_between_center">
                <span class="order_info_label">优惠券抵扣：</span>
                <span v-if="canUse_Coupon.length > 0" class="order_info_val" style="color:#ff6543;" @click.stop="showCoupon = true">
                    <span v-if="canUse_CouponIndex === ''">有可用的优惠券</span>
                    <span v-else>-￥{{canUse_Coupon[canUse_CouponIndex].coupon_money}}</span>
                </span>
                <span v-else class="order_info_val" style="color:#999999;">无可用优惠券</span>
            </div>
            <div class="order_shipping_price">预计运费 <span style="color:#ff6543;font-size: 16px;">￥{{estimate_price}}</span></div>
            <el-button type="primary" class="confirm_btn" @click.native="onSubmitOrder">开始用车</el-button>
            <div class="order_tips">最晚10分钟出车，请保持手机通畅</div>
        </div>
        <div style="clear: both;"></div>
        <!-- 货物参数 -->
        <el-dialog title="设置货物参数" :visible.sync="goods_parameter_show">
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物名称：
                </div>
                <el-input v-model="cargoDetails.cargo_name" placeholder="请输入货物名称"></el-input>
            </div>
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物体积：
                </div>
                <el-input v-model.number="cargoDetails.cargo_volume" type="number" placeholder="请输入货物体积,单位为立方米"></el-input>
            </div>
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物重量：
                </div>
                <el-input v-model.number="cargoDetails.cargo_all_weight" type="number" placeholder="请输入货物重量, 单位为吨"></el-input>
            </div>
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物数量：
                </div>
                <el-input v-model.number="cargoDetails.cargo_num" type="number" placeholder="请输入货物数量,为正整数"></el-input>
            </div>
            <div class="form_item flex_center">
                <el-button type="primary" @click.stop="set_goods_confirm">完成</el-button>
            </div>
        </el-dialog>
        <!-- 智能选车 -->
        <el-dialog title="智能选车" :visible.sync="choice_car_show">
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物体积：
                </div>
                <el-input v-model.number="car_goods_volume" type="number" @input="onVolumeInput" placeholder="请输入货物体积,单位为立方米"></el-input>
            </div>
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物重量：
                </div>
                <el-input v-model.number="car_goods_weight" type="number" @input="onWeightInput" placeholder="请输入货物重量, 单位为吨"></el-input>
            </div>
            <div class="form_item flex_center">
                <div class="rg_input_label">
                    货物数量：
                </div>
                <el-input v-model.number="car_goods_count" type="number" @input="onCountInput" placeholder="请输入货物数量,为正整数"></el-input>
            </div>
            <div class="form_item flex_center">
                <el-button type="primary" @click.stop="choice_car_confirm">完成</el-button>
            </div>
        </el-dialog>
        <!-- 可使用优惠券列表 -->
        <el-dialog title="可使用优惠券" :visible.sync="showCoupon">
            <div v-for="(item,udx) in canUse_Coupon" :key="udx" :class="['coupon_style',canUse_CouponIndex === udx?'coupon_style_active':'']" @click.stop="choiceCoupon(udx)">
                <div class="coupon_main">
                    <div class="coupon_lf">
                        <div class="coupon_top">￥<span style="font-size:24px;">{{item.coupon_money}}</span></div>
                        <div class="coupon_center">有效期至{{item.end_time_text}}</div>
                        <div class="coupon_bottom">满{{item.min_order_amount}}可用</div>
                    </div>
                    <img v-if="canUse_CouponIndex === udx" class="coupon_active_icon" src="@assets/imgs/check_icon.png"></img>
                </div>
            </div>
        </el-dialog>
        <!-- 常用路线 -->
        <!-- 可使用优惠券列表 -->
        <el-dialog class="dialog_route" title="常用路线" :visible.sync="showCommonRoute">
            <div class="distance_wrap">
                <el-steps class="step_route" direction="vertical" :active="1" v-for="ftem in routeList" :key="ftem.haulway_sn">
                    <el-button type="primary" class="dialog_route_btn" @click.stop="selectRoute(ftem)">一键导入</el-button>
                    <el-step :class="['step_item',item.action == 1?'start_step_item':'end_step_item']" v-for="(item,index) in ftem.nodeList" :key="index">
                        <template #icon>
                            <div class="step_icon" :style="{background: item.action != 1?'#ff6543':''}">{{item.action != 1?'卸':'装'}}</div>
                        </template>
                        <template #description>
                            <div class="step_description flex_between_center">
                                <div class="column_start">
                                    <span class="txt_font">{{item.address_name}}</span>
                                    <span class="txt_font" style="color:#aaa;" v-if="item.linkman_name">{{item.linkman_name}}</span>
                                    <span class="txt_font" style="color:#aaa;" v-if="item.linkman_phone">{{item.linkman_phone}}</span>
                                </div>
                            </div>
                        </template>
                    </el-step>
                </el-steps>
            </div>
        </el-dialog>
    </div>
</template>

<script>
var canPost = true;
import apis from "@/api/apis"
import {
    handleGetTIme,
    handleGetCounpon,
    handleGetCarType,
    handleCommonRoute,
    delCommonRoute,
    handleGetCargo,
    handleGetAutoCarType,
    handleSubmitOrder,
    handleGetAbleCoupon,
    handleGetRemarks
} from '@/api/placeOrder'
import {uploadImg} from "@/utils/util"
export default {
    name:'placeOrder',
    data() {
        return {
            isSubmitOrder:false,            //是否正在提交订单
            // 智能选车 弹窗
            choice_car_show:false,
            car_goods_volume:'',
            car_goods_weight:'',
            car_goods_count:'',

            // 货物参数 弹窗
            goods_parameter_show: false, //显示/隐藏 修改货物参数
            cargoList:[],           //常用货物名称
            cargoDetails:{
                cargo_name: '',
                cargo_volume: '',
                cargo_all_weight: '',
                cargo_num: '',
            },
            isConfirmGoods:false,       //是否已完成输入货物参数

            // 页面数据
            carList: [],    // 车型列表
            carType: 0,//车的类型    专车 回程车
            useType:1,//付费方式   实时下单  预约下单
            booking_date:'',    //预约日期
            booking_time: '', //预约时间
            arriayTime:'',      //预约用车时间
            wayPoint:[],        //所有装货卸货的途经点

            // right form
            warehouse:{
                po_number: '',
                marks: '',
            },
            order_file_list: [],        //入仓文件
            order_phone: '', //订单联系方式
            order_remarks: '', //订单备注
            dialogVisible: false,
            dialogImageUrl: '',

            costData:null,
            // 到达时间（提货时间）
            order_date:'',
            newDateArr:[],
            order_time:'',
            newTimeArr:[],
            estimate_price:0,      //预计运费
            addWayPointList:[],
            delWayPointList:[],
            point_index:'',

            // 优惠券数据
            couponData:null,      //可领取的优惠券

            showCoupon:false,
            canUse_Coupon:[],       //可使用的优惠券
            canUse_CouponIndex: '',      //选中的优惠券

            // 常用路线数据
            commonPage:1,
            routeList:[],
            showCommonRoute:false,

            // 备注数据
            remarksList:[],
            remarksVal:''
        }
    },
    created() {
        // this.onGetCounpon();
        this.onGetTime();
        this.onGetCarType();
        this.onGetRemarks();
    },
    mounted() {
    },
    activated() {
        let that = this;
        if(this.loc_type == 'end'){
            this.calCost()
        }
        this.$bus.on('set_startPoint', function (datas){
            if(datas.data){
                let _index = that.point_index;
                that.$set(that.addWayPointList,_index,datas.data)
            }
        });
        this.$bus.on('set_endPoint', function (datas){
            if(datas.data){
                let _index = that.point_index;
                that.$set(that.delWayPointList,_index,datas.data)
            }
        });
    },
    computed:{
        userData:function(){
            return this.$store.getters.userData || this.$storage.get('userData')
        },
        carList_index:function(){
            return this.$store.getters.carList_index;
        },
        pendant_id:function(){
            return this.$store.getters.pendant_id;
        },
        selectCar:function(){
            return this.$store.getters.selectCar;
        },
        startLocation:function(){
            return this.$store.getters.startLocation
        },
        endLocation:function(){
            return this.$store.getters.endLocation
        },
        loc_type:function(){
            return this.$store.getters.loc_type
        }

    },
    methods: {
        onIndexOf(str){
            if(str == '' || str == undefined){
                return -1
            }else{
                return str.indexOf('image')
            }
        },
        onVolumeInput(evt){
            this.$set(this.cargoDetails,'cargo_volume',evt)
        },
        onWeightInput(evt){
            this.$set(this.cargoDetails,'cargo_all_weight',evt)
        },
        onCountInput(evt){
            this.$set(this.cargoDetails,'cargo_num',evt)
        },
        remarksChange(evt){
            this.order_remarks = evt;
            console.log("选择",evt,this.remarksVal);

        },
        getRenark(item){
            console.log("选择",item);
             this.order_remarks=item.msg
        },
        choiceCoupon(index) {
            this.canUse_CouponIndex = index;
            this.calCost()
            this.showCoupon = false;
        },
        onShowRoute(){
            this.onGetCommonRoute();
        },
        // 导航栏 选择车型
        selectPandent(e) {
            let item = e || '';
            let pendant_id = this.pendant_id
            if (item && item.id) {
                let index = pendant_id.indexOf(item.id)
                if (index > -1) {
                    pendant_id.splice(index, 1)
                } else {
                    pendant_id.push(item.id)
                }
            } else {
                pendant_id = []
            }
            this.$store.commit('SET_PENDANTID',pendant_id.sort())
            this.calCost()
        },
        //选择用车类型(专车、回程车)
        selectCarType(e) {
            let type = e;
            let costData = this.costData
            if (type == 1 && costData && costData.canBack == 0) {
                this.$notify.info({
                    title:'提示',
                    message:'订单公里数小于起步公里数，只能选择专车'
                })
                type = 0
            }
            this.carType = type;
        },
        // 选择装货地址信息
        choiceStartLocation(){
            this.$store.commit('SET_LOCTYPE','start')
            let startLocation = this.startLocation;
            if(startLocation.address != ''){
                this.$router.push({path:'/index/mapChoice',query:{latlng:{lat:startLocation.latitude,lng:startLocation.longitude}}})
            }else{
                this.$router.push({path:'/index/mapChoice'})
            }

        },
        // 选择卸货地址信息
        choiceEndLocation(){
            this.$store.commit('SET_LOCTYPE','end')
            let endLocation = this.endLocation;
            if(endLocation.address != ''){
                this.$router.push({path:'/index/mapChoice',query:{latlng:{lat:endLocation.latitude,lng:endLocation.longitude}}})
            }else{
                this.$router.push({path:'/index/mapChoice'})
            }
        },
        choiceStartLocationPoint(adx){
            this.$store.commit('SET_LOCTYPE','point')
            this.$store.commit('SET_ACTION','1')
            this.$store.commit('SET_POINT_INDEX',adx)
            let addWayPointList = this.addWayPointList;
            this.point_index = adx;
            // console.log("addWayPointList",addWayPointList)
            if(addWayPointList[adx].latitude && addWayPointList[adx].longitude){
                this.$router.push({path:'/index/mapChoice',query:{latlng:{lat:addWayPointList[adx].latitude,lng:addWayPointList[adx].longitude}}})
            }else{
                this.$router.push({path:'/index/mapChoice'})
            }
        },
        choiceEndLocationPoint(dex){
            this.$store.commit('SET_LOCTYPE','point')
            this.$store.commit('SET_ACTION','0')
            this.$store.commit('SET_POINT_INDEX',dex)
            this.point_index = dex;
            let delWayPointList = this.delWayPointList;
            if(delWayPointList.length > 0 && delWayPointList[dex].address){
                this.$router.push({path:'/index/mapChoice',query:{latlng:{lat:delWayPointList[dex].latitude,lng:delWayPointList[dex].longitude}}})
            }else{
                this.$router.push({path:'/index/mapChoice'})
            }
        },
        //新增装货途经点
        onAddStartWayPoint() {
            this.addWayPointList.push({
                name: '',
                address: '',
                linkman_name: '',
                linkman_phone: '',
                action: 1
            })
            this.$store.commit('SET_STARTWAYPOINT',this.addWayPointList)
        },
        //删除装货途经点
        onDelStartWayPoint(e) {
            let index = e;
            let addWayPointList = this.addWayPointList
            addWayPointList.splice(index, 1)
            this.addWayPointList = addWayPointList;
            this.$store.commit('SET_STARTWAYPOINT',this.addWayPointList)
            this.connectWayPoint()
            this.calCost()
        },
        // 添加卸货地点
        onAddEndWayPoint() {
            this.delWayPointList.push({
                name: '',
                address: '',
                linkman_name: '',
                linkman_phone: '',
                action: 0
            })
            this.$store.commit('SET_ENDWAYPOING',this.delWayPointList)
        },
        // 删除卸货地点
        onDelEndWayPoint(e) {
            let index = e;
            let delWayPointList = this.delWayPointList
            delWayPointList.splice(index, 1)
            this.delWayPointList = delWayPointList;
            this.$store.commit('SET_ENDWAYPOING',this.delWayPointList)
            this.connectWayPoint()
            this.calCost()
        },
        // 合并所有途经点
        connectWayPoint() {
            let addWayPointList = this.addWayPointList
            let delWayPointList = this.delWayPointList
            let wayPoint = addWayPointList.concat(delWayPointList)
            this.wayPoint = wayPoint;
        },
        //计算费用
        calCost() {
            let startLocation = this.startLocation
            let endLocation = this.endLocation
            if(startLocation.address == '' || endLocation.address == ''){
                return false
            }
            if(this.carList_index == 0){
                this.$message.info('请先选择用车类型')
                return false;
            }
            
            let wayPoint = this.wayPoint
            if (wayPoint.length > 0) {
                for (let item of wayPoint) {
                    if (item.name == '') {
                        return false;
                    }
                }
            }
            canPost = false
            let trip_type = this.useType;
            let back_car = this.carType;
            let carList = this.carList;
            let carList_index = this.carList_index;
            let car_type_id = this.selectCar.id;
            let pendant_id = this.pendant_id;
            let canUse_CouponIndex = this.canUse_CouponIndex
            let estimate_price = 0;
            this.$store.dispatch('calCost',{startLocation, endLocation, wayPoint, trip_type, back_car, car_type_id, pendant_id:pendant_id.toString()}).then(datas=>{
                // console.log("计算价格之后datas====>",datas)
                canPost = true;
                if (this.carType == 1) {
                    if (datas.canBack == 0) {
                        this.$message.info('订单公里数小于起步公里数，只能选择专车')
                        this.carType = 0;
                    }
                }
                this.costData = datas;
                if (back_car == 0) {
                    estimate_price = datas.zhuanche_money
                } else {
                    estimate_price = datas.huichengche_money
                }
                if(canUse_CouponIndex !== ''){
                    let canUse_Coupon = this.canUse_Coupon
                    let couponItem = canUse_Coupon[canUse_CouponIndex]
                    let value = couponItem.coupon_money
                    estimate_price = parseFloat(estimate_price) - parseFloat(value)
                }
                this.estimate_price = estimate_price;
                this.onGetAbleCoupon();
            }).catch(err=>{

            })
        },
        // 下单叫车
        onSubmitOrder(){
            let cargoDetails = this.cargoDetails;
            let contactPhone = this.order_phone;
            let warehouse = this.warehouse;
            let orderImg = this.order_file_list;
            let userData = this.userData;
            let trip_type = this.useType;
            let back_car = this.carType;
            let car_type_id = this.selectCar.id;
            let couponItem = this.canUse_Coupon[this.canUse_CouponIndex];
            let newDateArr = this.newDateArr;
            let newTimeArr = this.newTimeArr;
            if(this.order_time === '' || this.order_date === ''){
                this.$message.warning('请先选择到达时间')
                return false;
            }
            let arriveDate=newDateArr.find(item=>item.value===this.order_date)
            let arriveTime=newTimeArr.find(item=>item.value===this.order_time)

            // cargoDetails.arrive_time = `${newDateArr[this.order_date].label}${newTimeArr[this.order_time].label}`;
            cargoDetails.arrive_time = `${arriveDate.label}${arriveTime.label}`;
            console.log("提取到达时间",);
            if(this.addWayPointList.length > 0 || this.delWayPointList.length > 0){
                this.connectWayPoint();
            }


            let tripDate=newDateArr.find(item=>item.value===this.booking_date)
            let tripTime=newTimeArr.find(item=>item.value===this.booking_time)
           
            
            if (this.isAllowSubmit(cargoDetails, contactPhone) == true) {
                if (trip_type == 0&&(!tripDate || !tripTime)) {
                    this.$message.warning('请先设置完整预约时间')
                    return
                }
                this.isSubmitOrder = true;
                let cargo_info = cargoDetails
                cargo_info.car_type_id = car_type_id
                let datakey = {}
                datakey['user_sn'] = userData.user_sn
                datakey['cargo_info'] = JSON.stringify(cargo_info)
                datakey['trip_type'] = trip_type
                datakey['user_assign_time'] = cargo_info.arrive_time
                datakey['link_phone'] = contactPhone
                // 设置预约时间
                if (trip_type == 0) {
                    let time = `${tripDate.label}${tripTime.label}`
                    console.log("编辑trip_time",newDateArr,newTimeArr,this.booking_date,this.booking_time);
                    datakey['trip_time'] = time
                }

                datakey['start_location'] = JSON.stringify(this.startLocation)
                datakey['end_location'] = JSON.stringify(this.endLocation)
                if (this.wayPoint.length > 0) {
                    datakey['way_point'] = JSON.stringify(this.wayPoint)
                }
                datakey['user_estimate_amount'] = this.estimate_price
                datakey['back_car'] = back_car
                let order_remarks = this.order_remarks
                if (order_remarks) {
                    datakey['user_remark'] = order_remarks
                }
                if (warehouse) {
                    if (warehouse.po_number) {
                        datakey['po_number'] = warehouse.po_number
                    }
                    if (warehouse.marks) {
                        datakey['shipping_mark'] = warehouse.marks
                    }
                }
                let uploadFileList = this.order_file_list
                if (uploadFileList.length > 0) {
                    let str = '';
                    for (let item of uploadFileList) {
                        str += str == '' ? item.url : ';' + item.url
                    }
                    datakey['inStorageImage'] = str
                }
                if (this.canUse_CouponIndex !== '') {
                    datakey['coupon_id'] = couponItem.id
                }
                if (this.pendant_id.length > 0) {
                    datakey['pendant_id'] = this.pendant_id.join(",")
                }
                console.log("参数",datakey);
                handleSubmitOrder(datakey).then(datas=>{
                    canPost = true
                    this.isSubmitOrder = false;
                    if (datas.status == 0) {
                        let cargoDetails = {
                            cargo_name:'',
                            cargo_volume:'',
                            cargo_all_weight:'',
                            cargo_num:''
                        }
                        this.cargoDetails = cargoDetails;
                        this.$store.commit('SET_ISSAVEROUTE',false)
                        let warehouse = {
                            po_number:'',
                            marks:''
                        }
                        this.warehouse = warehouse;
                        this.order_remarks = '';
                        this.$router.push({name:'MatchOrder',params:{order_sn:datas.data.order_sn,order_status:0}})
                    } else {
                        canPost = true
                        this.$message.error(datas.msg)
                    }
                }).catch(err=>{
                    this.isSubmitOrder = false;
                    canPost = true
                })
            }
        },
        //查看价格明细
        checkPirce(e) {
            let costData = this.costData
            if (costData == null) {
                this.$notify.info({
                    title:'提示',
                    message:'请先选择用车类型和用车地点'
                })
                return false
            }
            let type = e;
            costData.back_car = type
            let estimate_price
            if (type == 0) {
                estimate_price = costData.zhuanche_money
            } else {
                estimate_price = costData.huichengche_money
            }
            if(this.canUse_CouponIndex !== ''){
                let canUse_Coupon = this.canUse_Coupon
                let couponItem = canUse_Coupon[this.canUse_CouponIndex]
                let value = couponItem.coupon_money
                estimate_price = parseFloat(estimate_price) - parseFloat(value)
                costData.coupon_money = value;
            }
            costData.estimate_price = estimate_price
            this.$router.push({name:'PriceDetail',params:{datas:costData}})
        },
        //是否完善所有信息
        isAllowSubmit(cargoDetails, contactPhone) {
            if (cargoDetails.cargo_name == '' || cargoDetails.cargo_volume == '' || cargoDetails.cargo_all_weight == '' || cargoDetails.cargo_num == '') {
                this.$notify.warning({
                    title:'提示',
                    message:'请先确认货物参数详情'
                })
                return false
            }
            if (!contactPhone) {
                this.$notify.warning({
                    title:'提示',
                    message:'请先输入联系方式'
                })
                return false
            }
            return true
        },
        hasString(str1,str2){
            if(str1.indexOf(str2)==-1){
                return false
            }else{
                return true
            }
        },
        
        handleRemove(file) {
            let list = this.order_file_list;
            let index = list.findIndex(item=> item.uid == file.uid);
            list.splice(index,1);
            this.order_file_list = list;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //选择一个路线
        selectRoute(e) {
            this.$store.commit('SET_MAPROUTE',null)
            let item = e;
            let list = item.nodeList.map(item=>{
                item.name = item.address_name
                item.address = item.address_info
                item.latitude = item.lat
                item.longitude = item.lng
                return item;
            })
            let length = list.length
            this.$store.commit('SET_STARTLOCATION',list[0])
            this.$store.commit('SET_ENDLOCATION',list[length - 1])
            list.shift()
            list.pop()
            if(list.length > 0){
                let addWayPointList = []
                let delWayPointList = []
                for (let item of list) {
                    if (item.action == 1) {
                        addWayPointList.push(item)
                    } else {
                        delWayPointList.push(item)
                    }
                }
                this.addWayPointList = addWayPointList;
                that.$store.commit('SET_STARTWAYPOINT',addWayPointList)
                this.delWayPointList = delWayPointList;
                that.$store.commit('SET_ENDWAYPOING',delWayPointList)
            }
            this.wayPoint = list
            this.$store.commit('SET_CALCOST',true)
            this.showCommonRoute = false;
            this.calCost();
        },
        deleteRoute(e,index){
            let that = this
            let item = e;
            let datakey = {}
            datakey['user_sn'] = this.userData.user_sn
            datakey['haulway_sn'] = item.haulway_sn
            this.$confirm('是否确定删除此路线?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delCommonRoute(datakey).then(datas=>{
                    this.$notify.info({
                        title:'提示',
                        message:datas.msg
                    })
                    if (datas.status == 0) {
                        let list = this.routeList;
                        list.splice(index,1);
                        this.routeList = list;
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                      
            });
        },
        // 开始用车
        useCar() {
            let startLocation = this.startLocation
            let endLocation = this.endLocation
            let wayPoint = this.wayPoint
            if (startLocation == null || endLocation == null) {
                this.$message.warning('请完善用车路线')
                return false;
            }
            if (wayPoint.length > 0) {
                for (let item of wayPoint) {
                    if (item.name == '') {
                        this.$message.warning('请完善途经点地址')
                        return false;
                    }
                }
            }
            let time = this.arriayTime
            let route = {}
            route.startLocation = startLocation
            route.endLocation = endLocation
            route.wayPoint = wayPoint
            this.$store.commit('SET_MAPROUTE',route)
            let costData = this.costData
            let useType = this.useType
            let carType = this.carType
            if (useType == 0 && !time) {
                this.$message.warning('请先设置预约用车时间')
                return false;
            }
            let carList = this.carList
            let carList_index = this.carList_index
            if (carList_index == 0) {
                this.$message.warning('请选择用车类型')
                return false
            }
            let pendant_id = this.pendant_id
            // wx.navigateTo({
            //     url: '/subPackages/pages/submitOrder/submitOrder?useType=' + useType + '&carType=' + carType + '&time=' + time + '&car_type_id=' + carList[scrollIndex].id + '&pendant_id=' + pendant_id.toString(),
            // })
        },

        // 完成设置货物参数
        set_goods_confirm(){
            let {cargo_name,cargo_volume,cargo_all_weight,cargo_num} = this;
            if(cargo_name == ''){
                this.$message.error('请输入货物名称')
                return false;
            }
            if(cargo_volume == ''){
                this.$message.error('请输入货物体积')
                return false;
            }
            if(cargo_all_weight == ''){
                this.$message.error('请输入货物重量')
                return false;
            }
            if(cargo_num == ''){
                this.$message.error('请输入货物数量')
                return false;
            }
            this.isConfirmGoods = true;
            this.goods_parameter_show = false;
        },
        parsePercentage(val) {
            return parseInt(val.percentage, 10);
        },
        onUploadFile(evt){
            let param = evt;
            param.status = 'uploading';
            uploadImg(param.file,param.file.type,(progressEvent) => {
                //上传进度
                let num = progressEvent.loaded / progressEvent.total * 100 | 0;  //百分比
                param.percentage = num;
                param.onProgress({percent: num})     //进度条
            }).then(res=>{
                if(res.status == 0){
                    this.$notify.success({
                        title:'提示',
                        message:res.msg
                    })
                    param.status = 'success';
                    param.onSuccess(); //上传成功(打钩的小图标)
                    this.order_file_list.push({name:param.file.name,uid:param.file.uid,status:'success',url: res.data.imageUrl,type:param.file.type})
                }
            }).catch(err=>{
                this.$message.error(err.msg)
            })
            
        },

        // 完成智能选车
        choice_car_confirm(){
            this.onGetAutoCarType({
                cargo_volume:this.car_goods_volume,
                cargo_all_weight:this.car_goods_weight,
                cargo_num:this.car_goods_count
            })
            this.choice_car_show = false;
            this.isConfirmGoods = true;
        },
        changeNav(evt) {
            this.onSelectCar(evt);
        },
        carouselChange(evt) {
            this.onSelectCar(evt);
        },
        //改版选择的车
        onSelectCar(index) {
            if (canPost) {
                let carList = this.carList
                this.$store.commit('SET_SELECTCAR',carList[index])
                this.$refs['car'].setActiveItem(index)
                this.$store.commit('SET_CARLIST_INDEX',index)
                this.$store.commit('SET_PENDANTID',[])
                if (index > 0) {
                    this.calCost()
                }
                
            }

        },
        // 获取智能选车后的匹配车型
        onGetAutoCarType(data){
            let datakey = {}
            datakey['cargo_volume'] = data.cargo_volume
            datakey['cargo_all_weight'] = data.cargo_all_weight
            datakey['cargo_num'] = data.cargo_num
            handleGetAutoCarType(datakey).then(datas=>{
                if (datas.status == 0) {
                    let data = datas.data
                    let carList = this.carList
                    for (let i = 0; i < carList.length; i++) {
                        if (carList[i].id == data.id) {
                            this.onSelectCar(i)
                        }
                    }
                }
            }).catch(err=>{
                console.log(err)
            })
        },

        // 获取常用货物名称
        onGetCargo(){
            let userData = this.userData;
            let datakey = {};
            datakey['user_sn'] = userData.user_sn
            handleGetCargo(datakey).then(datas=>{
                if (datas.status == 0) {
                    this.cargoList = datas.data.cargo_name_list
                }
            }).catch(err=>{

            })
        },
        onGetTime(){
            handleGetTIme().then(datas=>{
                if (datas.status == 0) {
                    let data = datas.data
                    this.newDateArr = data.date.map((item,index)=>{
                        let obj = {
                            label : item,
                            value : index
                        }
                        return obj;
                    });
                    this.newTimeArr = data.describeList.map((item,index)=>{
                        let obj = {
                            label : item,
                            value : index
                        }
                        return obj;
                    });
                }
            }).catch(err=>{
                
            })
        },
        // 获取可领取优惠券
        onGetCounpon(){
            handleGetCounpon({
                user_sn:userData.user_sn
            }).then(datas=>{
                if (datas.status == 0) {
                    let data = datas.data
                    this.couponData = datas.data;
                }
            })
        },
        // 获取可使用优惠券
        onGetAbleCoupon(){
            let datakey = {};
            datakey['user_sn'] = this.userData.user_sn
            let estimate_price = this.estimate_price
            // console.log(estimate_price)
            datakey['total'] = estimate_price
            handleGetAbleCoupon(datakey).then(datas=>{
                if(datas.status == 0){
                    this.canUse_Coupon = datas.data;
                }
            }).catch(err=>{

            })
        },
        onGetCarType(){
            handleGetCarType().then(datas=>{
                if (datas.status == 0) {
                    let list = datas.data
                    let obj = {
                        "cart_type_name": "智能选车",
                    }
                    list.unshift(obj)
                    this.carList = list;
                }
            })
        },
        // 获取订单备注列表
        onGetRemarks(){
            let datakey = {};
            datakey['user_sn'] = this.userData.user_sn
            handleGetRemarks(datakey).then(datas=>{
                console.log("订单备注",datas);
                if(datas.status == 0){
                    let options = [
                        {
                            label:'历史备注',
                            // remarks:[datas.data.history.map((item)=>{
                            //     return {
                            //         value:item,
                            //         label:item
                            //     }
                            // })]
                            remarks:datas.data.history
                        },
                        {
                            label:'系统备注',
                            // remarks:datas.data.system.map((item)=>{
                            //     return {
                            //         value:item,
                            //         label:item
                            //     }
                            // })
                              remarks:datas.data.system
                        }
                    ]
                    this.remarksList = options;
                }
            }).catch(err=>{

            })
        },
        // 获取常用路线
        onGetCommonRoute(){
            let datakey = {};
            datakey['user_sn'] = this.userData.user_sn
            datakey['sort'] = 0;
            datakey['page'] = this.commonPage;
            datakey['status'] = 1;
            handleCommonRoute(datakey).then(datas=>{
                if (datas.status == 0) {
                    let allPageCount = datas.data.haulwayList.allPageCount
                    let newList = datas.data.haulwayList.haulwayList
                    this.routeList = newList;
                    this.showCommonRoute = true;
                }else{
                    this.showCommonRoute = true;
                    this.$message.error(datas.msg)
                }
            }).catch(err=>{
                this.showCommonRoute = true;
                this.$message.error(err.msg)
                console.log(err)
            })
        },
    },
}
</script>

<style lang="scss">
    .place_order_main{
        padding: 16px 0;
        .main_item {
            width: 50%;
            padding: 0 16px;
            float: left;
        }
        /*#region*/
        .main_rg_item {
            padding: 16px 40px;
        }

        .rg_form_title {
            font-size: 16px;
            line-height: 36px;
            color: #333;
        }

        .rg_input_area {
            padding: 12px;
            background: #f2f2f2;
            margin-bottom: 16px;
            width: 100%;
            border-radius: 4px;
        }

        .rg_input_text {
            font-size: 15px;
        }

        .rg_input_value {
            flex: 1;
            padding: 0 10px;
            font-size: 14px;
            text-align: left;
        }

        .rg_input_edit {
            font-size: 14px;
            color: #02a7f0;
            cursor: pointer;
        }

        .rg_form_item {
            margin-bottom: 12px;
            width: 100%;
        }
        .rg_form_select{
            margin-bottom: 12px;
            width: 100%;
        }

        .rg_input_label {
            font-size: 14px;
            width: 100px;
        }

        .rg_form_item .el-input {
            width: 0;
            flex: 1 1;
        }
        .remark_input{
            margin-right: 10px;
        }
        .rg_form_select .form_date_select{
            margin-right: 10px;
        }
        .form_date_select,.form_time_select{
            width: 50%;
        }

        .rg_input_area .el-upload {
            width: 80px;
            height: 80px;
            line-height: 85px;
        }

        .rg_input_area .form_upload {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
        }

        .rg_input_area .el-upload-list--picture-card .el-upload-list__item {
            width: 80px;
            height: 80px;
        }

        .rg_input_area .el-upload-list--picture-card .el-upload-list__item-thumbnail {
            object-fit: cover;
        }

        .rg_form_tips1 {
            padding-left: 100px;
            line-height: 1.8;
            font-size: 12px;
            color: #999;
        }

        .form_no_margin {
            margin-bottom: 0;
        }

        .order_info {
            padding: 0 20px 16px;
            width: 100%;
        }

        .order_info_label {
            font-size: 13px;
            color: #aaaaaa;
        }

        .order_info_val {
            font-size: 13px;
            color: #333;
            cursor: pointer;
        }

        .order_shipping_price {
            text-align: center;
            font-size: 12px;
            color: #aaaaaa;
        }

        .confirm_btn {
            width: stretch;
            margin: 8px 20px 6px;
        }

        .order_tips {
            font-size: 13px;
            color: #aaaaaa;
            text-align: center;
        }
        /*#endregion*/
        /*#region*/
        .main_line {
            border-right: 1px dashed #ddd;
        }

        .car_nav {
            width: 100%;
            margin: 16px 0;
        }

        .car_nav_item {
            color: #333;
            font-size: 14px;
            flex: 1;
            text-align: center;
            line-height: 1.3;
            cursor: pointer;
            position: relative;
        }

        .car_nav_active {
            color: #02a7f0;
        }

        .car_nav_item::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 0;
            bottom: -10px;
            left: 50%;
            margin: auto;
            transition: all .3s ease-in-out;
        }

        .car_nav_item:hover::after {
            width: 60%;
            left: 0;
            right: 0;
            background: #02a7f0;
        }
        .first_carousel,.onther_carousel{
            height: 100%;
        }
        .carousel_icon{
            width: 54px;
            height: 54px;
            margin-bottom: 20px;
        }
        .carousel_text{
            color: #aaa;
            font-size: 12px;
            margin-bottom: 20px;
        }
        .carousel_text span{
            line-height: 1.2;
        }
        .carousel_btn{
            width: 100px;
            height: 30px;
            border-radius: 30px;
            font-size: 13px;
            color: #fff;
            background: #00a4f4;
            cursor: pointer;
        }
        .car_info_item2 {
            padding: 15px 45px;
        }
        .car_img {
            height: 110px;
            width:auto;
        }
        .car_info_txt_view2 {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 10px;
            font-size: 13px;
            color: #7f7f7f;
            flex: 1;
            line-height: 25px;
        }
        .pendant_list_view{
            display: flex;
            flex-wrap: wrap;
            padding:10px 45px;
        }
        .pendant_item{
            border:1px solid #c0c0c0;
            padding:0 5px;
            height:20px;
            line-height: 20px;
            font-size: 12px;
            color:#7f7f7f;
            border-radius: 5px;
            margin-right: 5px;
            cursor: pointer;
        }
        .pendant_item:hover{
            border-color: #299EDE;
            color:#299EDE;
        }
        .select_pandent{
            color:#299EDE;
            border-color: #299EDE;
        }
        .place_header span {
            font-size: 15px;
        }

        .place_header .el-switch {
            margin-left: 10px;
        }

        .place_header .el-switch span {
            font-size: 12px;
        }

        .place_header .switchStyle .el-switch__label {
            position: absolute;
            display: none;
            color: #fff;
        }

        .place_header .switchStyle .el-switch__label--left {
            z-index: 9;
            left: 7px;
            line-height: 21px;
        }

        .place_header .switchStyle .el-switch__label--right {
            z-index: 9;
            left: -17px;
            line-height: 21px;
        }

        .place_header .switchStyle .el-switch__label.is-active {
            display: block;
        }

        .switchStyle.el-switch .el-switch__core,
        .el-switch .el-switch__label {
            width: 80px !important;
        }

        .place_txt1 {
            line-height: 40px;
        }

        .place_header .import_text {
            color: #02a7f0;
            font-size: 13px;
            cursor: pointer;
        }

        .distance_wrap .step_item {
            padding-right: 40px;
            position: relative;
        }

        .form_booking {
            padding: 0 40px;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            white-space: normal;
            text-align: left;
        }

        .form_item {
            margin-bottom: 12px;
        }

        .form_label {
            min-width: 120px;
            max-width: 120px;
        }

        .require_label {
            position: relative;
            padding-left: 10px;
        }

        .require_label::before {
            content: "*";
            color: #ff6543;
            font-size: 14px;
            position: absolute;
            left: 0;
            top: 4px;
            line-height: 1;
        }

        .add_icon {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 4px;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .step_description .input_username {
            margin-right: 10px;
        }
        .step_description .txt_font{
            font-size: 12px;
            color: #333;
            text-align: left;
        }
        .distance_wrap .step_icon {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            line-height: 30px;
            border: none;
            font-size: 14px;
            text-align: center;
            color: #fff;
            background-color: #02a7f0;
        }

        .distance_wrap .step_item .el-step__head.is-process {
            border-color: #ff6543;
            color: #fff;
        }

        .distance_wrap .start_step_item .el-step__head.is-process {
            // border-color: #ff6543;
            border-color: #409EFF;
            color: #fff;
        }
        .distance_wrap .start_step_item .el-step__head.is-wait{
            color: #409EFF;
            border-color: #409EFF;
        }
        .distance_wrap .step_item .el-step__icon {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            line-height: 30px;
        }
        .distance_wrap .end_step_item .el-step__head.is-wait{
            border-color: #ff6543;
        }
        .distance_wrap .end_step_item .el-step__head.is-wait{
            border-color: #ff6543;
        }
        .distance_wrap .el-step.is-vertical .el-step__head {
            width: 30px;
        }

        .distance_wrap .el-step.is-vertical .el-step__line {
            width: 2px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            margin-right: auto !important;
        }

        .distance_wrap .el-step__description {
            padding-right: 0;
        }
        /*#endregion*/

        /*#region*/
        .price_detail {
            padding-right: 40px;
        }

        .price_wrap {
            flex-wrap: wrap;
        }

        .price_item {
            width: 48%;
            margin-right: 4%;
            border-radius: 10px;
            position: relative;
            background: #f7f7f7;
            overflow: hidden;
            padding: 20px 10px;
        }

        .price_item:nth-child(2n + 2) {
            margin-right: 0;
        }

        .price_label {
            color: #aaaaaa;
            font-size: 14px;
            margin-bottom: 20px;
            width: 100%;
        }

        .price_label_size {
            font-size: 20px;
        }

        .price_num {
            font-size: 24px;
            color: #aaaaaa;
        }

        .detail_btn {
            height: 24px;
            line-height: 22px;
            padding: 0 6px;
            background: #fff;
            border: 1px solid #ddd;
            color: #aaaaaa;
            font-size: 12px;
            text-align: center;
            cursor: pointer;
        }

        .price_active_icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            height: 26px;
        }

        .price_active_color {
            color: #ff6543;
        }

        .price_active_bg {
            background: #fef1e1;
        }
        /*#endregion*/
        .dialog_route .el-dialog__body{
            padding: 16px 20px 0 20px;
        }
        .step_route{
            position: relative;
            padding-right: 100px;
            padding-bottom: 16px;
        }
        .dialog_route_btn{
            width: 100px;
            height: 30px;
            line-height: 30px;
            padding: 0;
            text-align: center;
            position: absolute;
            right: 0;
            top: 0;
        }
        .coupon_style {
            width: 100%;
            height: 90px;
            border: 1px solid #fff;
            background: #fe6565;
            position: relative;
            margin-bottom: 10px;
        }
        
        .coupon_style:last-child {
            margin-bottom: 0;
        }
        
        .coupon_style::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 16px;
            background: #fff;
            border-radius: 0 16px 16px 0;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            left: -1px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        
        .coupon_style::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 16px;
            background: #fff;
            border-radius: 16px 0 0 16px;
            border-left: 1px solid #fff;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            right: -1px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        
        .coupon_style_active {
            border: 1px solid #fff;
        }
        
        .coupon_style_active::before {
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
        }
        
        .coupon_style_active::after {
            border-left: 1px solid #fff;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
        }
        
        .coupon_style_disable {
            border: none;
            background: #f5f5f5;
        }
        
        .coupon_style_disable::before {
            border: none;
        }
        
        .coupon_style_disable::after {
            border: none;
        }
        .coupon_active_icon {
            position: absolute;
            right: -6px;
            top: -6px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
        }
        .coupon_main{
            height: 100%;
            position: relative;
            overflow: hidden;
        }
        .coupon_lf {
            z-index: 1;
            padding: 10px 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }
        
        .coupon_top {
            font-size: 12px;
            font-weight: bold;
            color: #fff;
        }
        
        .coupon_center {
            font-size: 12px;
            line-height: 1.4;
            color: #fff;
        }
        
        .coupon_bottom {
            color: #fff;
            font-size: 12px;
        }
        .coupon_style_disable .coupon_top {
            color: #666666;
        }
        
        .coupon_style_disable .coupon_center,
        .coupon_style_disable .coupon_bottom {
            color: #a0a0a0;
        }

        .upload_cover_box{
            height: 100%;
        }
    }
    
</style>